.mtb-default {
    margin-top: 7px;
    margin-bottom: 7px;
}

.ml-tool-panel {
    margin-left: 14px;
}

.ptblg-default {
    padding: 7px;
}

/*.content {
  min-height: calc(100vh - 100px);
}

.footer {
  height: 25px;
}*/

/*html, body {
  height: 100%;
  margin: 0;
}*/

.wrapper {
  min-height: calc(100vh - 70px);
  /*margin-bottom: -40px;*/
}

.lightStyle {
    background-color: white;
}

.greyStyle {
    background-color: #80808024;
}

.popup_inner_qr {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 330px;
  height: 430px;
  top: calc(50% - 215px);
  left: calc(50% - 165px);
  margin: auto;
  background: white;
}

.no-left-no-right-padding {
    padding-right: 0px;
    padding-left: 0px;
}

.cut-text { 
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.dropzone {
    background: #f7f5f3;
    border-radius: 5px;
    border: 2px dashed #d6d1ce;
    border-image: none;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
}

.dropzone-md {
    background: #f7f5f3;
    border-radius: 5px;
    border: 2px dashed #d6d1ce;
    border-image: none;
    min-height: 200px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
}

.push {
  height: 15px;
}

.footer {
    height: 50px;
}

.dropzone {
    background: #f7f5f3;
    border-radius: 5px;
    border: 2px dashed #d6d1ce;
    border-image: none;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
}

.dropzone-md {
    background: #f7f5f3;
    border-radius: 5px;
    border: 2px dashed #d6d1ce;
    border-image: none;
    min-height: 200px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
}

.serviceConnected {
    background-color: #FF4A39;
    color: white;
    padding: 5px 10px 0px 10px;
    height: 64px;
}

.serviceUCCConnected {
    color: white;
    background-color: #FF4A39;
    padding: 3px 0px 0px 0px;
    height: 32px;
}

.serviceUCCDisconnected {
    background-color: #dddddd;
    color: white;
    padding: 3px 0px 0px 0px;
    height: 32px;
}

.serviceCloudConnected {
    background-color: #FF4A39;
    color: white;
    padding: 3px 0px 0px 0px;
    height: 32px;
}

.serviceCloudDisconnected {
    background-color: #dddddd;
    padding: 3px 0px 0px 0px;
    height: 32px;
}

.fixed_header{
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.no-flex-wrap {
    flex-wrap: nowrap;
}

.fixed_header tbody{
  display:block;
  width: 100%;
  overflow-y: scroll;
  height: 200px;
}

.fixed_header thead tr {
   display: block;
}

.fixed_header thead {
  background: black;
  color:#fff;
}

.fixed_header th, .fixed_header td {
  padding: 5px;
  text-align: left;
  
}

.margin-left-20-font-size {
    margin-left: -20px;
    font-size: 1.4rem;
}

.serviceDisconnected {
    background-color: #dddddd;
    padding: 5px 10px 0px 10px;
    height: 64px;
}

.ccol-4 {
  -ms-flex: 0 0 38%;
  flex: 0 0 38%;
  max-width: 38%;
}

.ccol-8 {
  -ms-flex: 0 0 59%;
  flex: 0 0 59%;
  max-width: 59%;
}

.evenClassName {
    background-color: #f2f2f2;
    vertical-align: middle;
    display: block;
}

.setSelectedPath {
    border: 1px solid;
    background-color: #57bef2;
}

.oddClassName {
    background-color: white;
    vertical-align: middle;
    display: block;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}

.message-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}

.message-popup-inner {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    background: white;
    overflow-y: auto;
}

.popup_inner {
    position: absolute;
    left: 30%;
    right: 30%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: transparent;
}

.popup_inner_2f {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -30%);
    max-width: 400px;
    min-width: 350px;
    background: transparent;
}

.popup_inner_user_token_pin {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: auto;
  height: auto;
  max-width: 800px;
  min-height: 600px;
  position: relative;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
  background: white;
  overflow: hidden;
}

.container {
    color: #888888;
    max-width: 1080px;
}

.dl-horizontal dt {
    white-space: normal;
    width: 250px;
    clear: left;
    text-align: right;
}

.color-transition {
    -webkit-transition: color 2s;
    transition: color 2s;

}

.dl-horizontal dd {
    margin-left: 270px;
    float: left;
    margin-bottom: -5px;
    margin-top: -24px;
}

.dl-horizontal {
    margin-bottom: 0px;
}

.no-margin {
    margin: 0px;
}

.margin-right-7 {
    margin-right: 7px;
}

.signer-details {
    padding-top: 2px;
    font-size: 12px;
}

.no-left-padding {
    padding-left: 0px;
}

.left-padding-5 {
    padding-left: 5px;
}

.no-left-right-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.no-left-right-margin {
    margin-left: 0px;
    margin-right: 0px;
}

.margin-top-sm {
    margin-top: 0px;
}

.margin-bottom-sm {
    margin-bottom: 8px;
}

.no-letf-margin {
    margin-left: 0px;
}

.margin-bottom-8 {
    margin-bottom: 0px;
}

.margin-bottom-5 {
    padding-bottom: 0px;
}

.btn-default {
    background-color: #dddddd;
}

.max-width {
    max-width: 100%;
}

.margin-padding-0 {
    margin: 0;
    padding: 0;
}

.visual-impairment {
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-height: 60;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    transform: translateY(0);
}

.grayscale-100 {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}

.visual-impairment-div {
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 0;
}

.visual-impairment-container {
    padding: 0px;
    width: 100%;
    float: left;
    overflow: hidden;
}

.visual-impairment-buttons {
    display: flex;
    width: 85%;
    flex-direction: row;
    justify-content: center;
}

.visual-impairment-hide {
    width: 15%;
    display: flex;
    justify-content: flex-end;
}

.visual-impairment-filter {
    margin-bottom: 0;
    justify-content: center;
    display: flex;
    align-items: center;
}

.visual-impairment-title {
    font-size: 1rem;
    line-height: 1.5rem;
}

.visual-impairment-panel-buttons {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #cbffc0;
    background: #cbffc0;
    margin-left: 16px;
    transition: 0.3s;
}

.visual-impairment-panel-size {
    justify-content: center;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    display: flex;
    align-items: center;
}

.revert-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.right-download-panel {
    width: 74px;
    text-align: right;
    position: absolute;
    top: 228px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.03);
    border-top-left-radius: 10px 10px;
    border-bottom-left-radius: 10px 10px;
    padding-top: 10px;
}

.right-download-button {
    border: 1px solid #888888;
    color: #888888;
    border-radius: 1rem;
    width: 44px;
    height: 44px;
    margin-right: 15px;
    margin-bottom: 10px;
    margin-left: 15px;
}

.view-after {
    text-align: center;
    display: none;
}

.view-after-download-button {
    border: 1px solid #888888;
    color: #888888;
    border-radius: 1rem;
    width: 44px;
    height: 44px;
    margin-left: 5px;
    margin-right: 5px;
}

.poltava-bg {
  background-position: center;
  background: linear-gradient(to top right, #F2A5B9, #FF4A39);
  /*background-color: #FF4A39;*/
}

.img-padding {
    padding: 10px;
}

.btn-header {
    background-color: #FF4A39;
    color: white;
    border-color: white;
}

.btn-header-active {
    background-color: white;
    color: #1a2251;
}

.btn-header:hover {
    background-color: #CC3426;
    color: white;
    border-color: white;
}

.marginTop-marginBottom {
    padding-top: 14px;
    padding-bottom: 10px;
}

@media (max-width: 1205px) {
    .view-after {
        display: initial;
    }

    .right-download-panel {
        display: none;
    }
}

.visual-impairment-content-font-size-1 {
  font-size: 16px;
}

.visual-impairment-content-font-size-2 {
  font-size: 18px;
}

.visual-impairment-content-font-size-3 {
  font-size: 22px;
}

.visual-impairment-content-font-size-4 {
  font-size: 26px;
}

.header-span:hover {
  background-color: lightgray;
}

@media (max-width: 991px) {
    .no-left-padding {
        padding-left: 15px;
    }
    .dl-horizontal dt {
        width: 100%;
        clear: right;
        text-align: left;
    }
    .dl-horizontal dt {
        width: 100%;
        clear: right;
        text-align: left;
    }
}

@media (max-width: 767px) {
    .margin-top-sm {
        margin-top: 7px;
    }
    .margin-bottom-sm {
        margin-bottom: 0px;
    }

    .margin-bottom-8 {
        margin-bottom: 15px;
    }

    .margin-bottom-5 {
        padding-bottom: 5px;
    }
    .max-width {
        max-width: 100%;
    }

    .visual-impairment-panel-size {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .visual-impairment-filter {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .visual-impairment-panel-size {
        padding-right: 0px;
        padding-left: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .visual-impairment-title {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        width: unset;
        text-align: left;
    }

    .visual-impairment-panel-buttons {
        margin-left: 5px;
    }
}

@media (max-width: 575px) {
    .text-center {
      text-align: left !important;
    }
    .no-flex-wrap {
        flex-wrap: wrap;
    }

    .no-right-padding {
        padding-right: 0px;
    }
}

@media (max-width: 412px) {
    .margin-left-20-font-size {
        margin-left: 0px;
        font-size: 1.0rem;
    }
}

@media (max-width: 575px) {
    .add-files .col-sm-10, .add-files .col-xs-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .add-files .col-sm-9, .add-files .col-xs-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .add-files .col-sm-1, .add-files .col-xs-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
}

#dscLeftParamArea label + span,
#dsvLeftParamArea label + span {
    margin-left: -17px;
    margin-top: 5px;
}

.grey-tooltip + .tooltip > .tooltip-inner {
    background-color: #555555;
}
.grey-tooltip + .tooltip > .tooltip-arrow {
    border-bottom-color: #555555;
}

textarea {
    resize: none;
    overflow-x: hidden;
}